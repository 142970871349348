<!-- 订单详情 -->
<template>
  <div class="order-detail-container">
    <div class="service-side" @click="handleCsInfo(info)">
      <img src="../../assets/image/service-side.png" alt="" />
    </div>
    <div class="base-info-wrapper">
      <div class="bg">
        <!-- <div class="product-title"> -->
        <div class="title">{{ info.productName }}</div>
        <!-- </div> -->
        <div class="base-info">
          <!-- guarantee surrender -->
          <p class="order-status guarantee" v-if="info.newStatus == 1">待支付</p>
          <p class="order-status guarantee" v-if="info.newStatus == 2">已支付</p>
          <p class="order-status guarantee" v-if="info.newStatus == 3">保障中</p>
          <p class="order-status surrender" v-if="info.newStatus == 4">已退保</p>
          <p class="order-status surrender" v-if="info.newStatus == 5">已失效</p>
          <p class="order-status surrender" v-if="info.newStatus == 6">已关闭</p>
          <p class="order-status orange" v-if="info.newStatus == 0">{{ info.statusName }}</p>

          <img v-if="info.newStatus == 4 && info.surrender" class="seal" src="../../assets/image/guarantee-gray-seal.png" alt="" />
          <img v-if="info.newStatus == 3" class="seal" src="../../assets/image/guaranteeing-seal.png" alt="" />
          <div class="top-wrapper">
            <p>
              <span class="label">订单号 </span>
              <span class="value">{{ info.orderSn }}</span>
            </p>
            <p v-if="info.insuranceSn">
              <span class="label">保单号 </span>
              <span class="value">{{ info.insuranceSn }}</span>
            </p>
            <p v-if="info.applyInsSn">
              <span class="label">投保单号 </span>
              <span class="value">{{ info.applyInsSn }}</span>
            </p>
            <p>
              <span class="label">回访状态 </span>
              <span class="value">{{ info.visitStatus }}</span>
            </p>
            <p>
              <span class="label">回访时间 </span>
              <span class="value">{{ info.visitTime ? info.visitTime : "无" }}</span>
            </p>
            <p>
              <span class="label">投保时间 </span>
              <span class="value">{{ info.insuredTime }}</span>
            </p>
            <p>
              <span class="label">保障期限 </span>
              <span class="value">{{ info.insStartTime ? info.insStartTime : "--" }} 至 {{ info.insEndTime ? info.insEndTime : "--" }}</span>
            </p>
            <p>
              <span class="label">交费期限 </span>
              <span class="value">{{ info.payPeriod }}</span>
            </p>
            <p>
              <span class="label">保费 </span>
              <span class="value">{{ info.premium }}元</span>
            </p>
          </div>
          <!-- <div class="bottom-wrapper">
            <h4>基本信息</h4>
            <div class="content common-content"></div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 投保人信息 -->
    <div class="section applicant-section">
      <h4 class="title">
        <p>投保人信息</p>
      </h4>
      <div class="common-content" v-if="info.applicant">
        <p>
          <span class="label">姓名 </span>
          <span class="value">{{ info.applicant.name }}</span>
        </p>
        <p>
          <span class="label">证件类型 </span>
          <span class="value">{{ info.applicant.cardType }}</span>
        </p>
        <p>
          <span class="label">手机号 </span>
          <span class="value">{{ info.applicant.mobile }}</span>
        </p>
        <p>
          <span class="label">证件号码 </span>
          <span class="value">{{ info.applicant.cardNo }}</span>
        </p>

        <p>
          <span class="label">电子邮箱 </span>
          <span class="value">{{ info.applicant.email }}</span>
        </p>
        <p>
          <span class="label">联系地址 </span>
          <span class="value">{{ info.applicant.address }}</span>
        </p>
      </div>
    </div>
    <!-- 被保人信息 -->
    <div class="section insured-section">
      <h4 class="title">
        <p>被保人信息</p>
      </h4>
      <div class="common-content">
        <div class="insured-item" v-for="(item, index) in info.insured" :key="index">
          <div class="insured-no">被保人信息{{ index + 1 }}</div>
          <div class="insured-content">
            <p>
              <span class="label">姓名 </span>
              <span class="value">{{ item.name }}</span>
            </p>
            <p>
              <span class="label">证件类型 </span>
              <span class="value">{{ item.cardType }}</span>
            </p>
            <p>
              <span class="label">手机号 </span>
              <span class="value">{{ item.mobile }}</span>
            </p>
            <p>
              <span class="label">证件号码 </span>
              <span class="value">{{ item.cardNo }}</span>
            </p>

            <p>
              <span class="label">电子邮箱 </span>
              <span class="value">{{ item.email ? item.email : "未知" }}</span>
            </p>
            <p>
              <span class="label">联系地址 </span>
              <span class="value">{{ item.address }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 受益人信息 -->
    <div class="section beneficiary-section">
      <h4 class="title">
        <p>受益人</p>
      </h4>
      <div class="common-content">
        <p>
          <span class="label">受益人</span>
          <span class="value" v-if="info.beneficiary.length > 0">指定受益人</span>
          <span class="value" v-else>法定受益人</span>
        </p>
        <!-- 顺位 -->
        <template v-if="info.beneficiary.length > 0">
          <div class="ben-order" v-for="(item, index) in info.beneficiary" :key="index">
            <div class="ben-no">第{{ index + 1 }}顺位受益人</div>
            <div class="ben-content">
              <div class="ben-item" v-for="(subItem, subIndex) in item" :key="subIndex">
                <p>
                  <span class="label">受益人{{ subIndex + 1 }}</span>
                  <span class="value">{{ subItem.name }}</span>
                </p>
                <p>
                  <span class="label">比例</span>
                  <span class="value">{{ subItem.beneProportion }}</span>
                </p>
                <p>
                  <span class="label">身份证</span>
                  <span class="value">{{ subItem.cardNo }}</span>
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- riskType：1=长险-->
    <div class="section" v-if="info.isSupportReNew && info.product.riskType == 1">
      <h4 class="title">
        <p>续期信息</p>
      </h4>
      <div class="common-content">
        <div v-if="info.curPeriod">
          <p>
            <span class="label" style="flex: 0 0 6em">本期缴费状态</span>
            <span class="value"
              >{{ info.curPeriod.payStatusName || "--" }}
              <Explain v-if="info.curPeriod.failureReason" :title="info.curPeriod.payStatusName">{{ info.curPeriod.failureReason }}</Explain>
            </span>
          </p>
          <p>
            <span class="label" style="flex: 0 0 6em">缴费计划</span>
            <span class="value" @click="renewPopup = true"
              >已缴{{ info.renewTotal.paidPeriodNo }}期/共{{ info.renewPeriodList.length }}期 <van-icon name="arrow"
            /></span>
          </p>
          <p>
            <span class="label" style="flex: 0 0 6em">当期续费时间</span>
            <span class="value">{{ getDate(info.curPeriod.shouldPayTime) || "--" }} ~ {{ getDate(info.curPeriod.expiredTime) }}</span>
          </p>
          <p>
            <span class="label" style="flex: 0 0 6em">当期续缴保费</span>
            <span class="value">{{ info.curPeriod.shouldPayMoney }}</span>
          </p>
        </div>
        <p>
          <span class="label" style="flex: 0 0 6em">续费账户 </span>
          <span class="value">尾号（{{ info.bankCardNo }}）</span>
        </p>
      </div>
    </div>

    <!-- riskType： 2=短险 支持续期 -->
    <div class="section" v-if="info.isSupportRenewal && renewalList.length">
      <h4 class="title">
        <p>续保保单</p>
      </h4>

      <div class="common-content" v-for="r in renewalList" :key="r.id">
        <p class="order-status surrender">
          {{ statusOpetion[r.newStatus] || "" }}
        </p>
        <div class="product-name">{{ r.productName }}</div>
        <p>
          <span class="label">被保人</span>
          <span class="value">{{ r.insuredName }}</span>
        </p>
        <p>
          <span class="label">保障期限</span>
          <span class="value">{{ getDate(r.insStartTime) }}~{{ getDate(r.insEndTime) }}</span>
        </p>
      </div>
    </div>

    <div class="bottom-bar btn-group" ref="btnGroup" v-show="showBottomBar">
      <van-popover v-if="info.newStatus == 3" v-model="showMore" placement="top-start" trigger="click">
        <div role="menu" class="van-popover__content">
          <div @click.stop="showPreservationGuide(info.product.guideText)" role="menuitem" class="van-popover__action">
            <div class="van-popover__action-text van-hairline--bottom">变更信息</div>
          </div>
          <div
            v-if="info.visitStatus != 1 && info.product.needVisit == 1 && info.insuranceUrl"
            @click.stop="showPreservationGuide(info.product.guideText)"
            role="menuitem"
            class="van-popover__action"
          >
            <div class="van-popover__action-text van-hairline--bottom" @click.stop="showVisitGuide(info.product.guideText, info.visitUrl)">回访</div>
          </div>
          <div @click.stop="" v-if="info.addInsUrl" role="menuitem" class="van-popover__action">
            <div class="van-popover__action-text van-hairline--bottom" @click.stop="toUrl(info.addInsUrl)">加保</div>
          </div>
        </div>
        <template #reference>
          <span class="more">更多</span>
        </template>
      </van-popover>
      <div class="right" ref="btnRight">
        <button v-if="info.status == 13 && info.nextUrl" @click.stop="toUrl(info.nextUrl)" class="btn orange-btn">继续投保</button>
        <!-- 人核状态 -->
        <template v-if="info.newStatus == 0">
          <!--  核保完成 -->
          <template v-if="info.manual.status == 5">
            <button v-if="info.manual.exclusionUrl" class="btn orange-btn" @click.stop="toUrl(info.manual.exclusionUrl)">确认并支付</button>
          </template>
          <!--  已撤销 -->
          <template v-else-if="info.manual.status == 6">
            <button class="btn" @click="deleteOrder(info.id)">删除</button>
          </template>
          <!-- 核保中 -->
          <template v-else>
            <button v-if="info.manual.exclusionUrl" class="btn orange-btn" @click.stop="toUrl(info.manual.exclusionUrl)">补充人核资料</button>
          </template>
        </template>

        <!-- 待支付 -->
        <template v-if="info.newStatus == 1">
          <button v-if="info.manual.orderSn && info.manual.exclusionUrl" class="btn orange-btn" @click.stop="toUrl(info.manual.exclusionUrl)">
            确认并支付
          </button>
          <button v-else-if="info.payUrl" class="btn orange-btn" @click.stop="toUrl(info.payUrl)">立即支付</button>
        </template>
        <!-- 已支付 -->
        <template v-if="info.newStatus == 2">
          <button
            v-if="info.visitStatus != 1 && info.product.needVisit == 1"
            class="btn"
            @click.stop="showVisitGuide(info.product.guideText, info.visitUrl)"
          >
            回访
          </button>
          <button class="btn" @click.stop="showPreservationGuide(info.product.guideText)">变更信息</button>
          <button v-if="info.role == 1 || info.role == 3" class="btn" @click.stop="showSurrenderGuide(info.product.guideText)">退保</button>
        </template>
        <!-- 保障中 -->
        <template v-if="info.newStatus == 3">
          <button
            v-if="info.visitStatus != 1 && info.product.needVisit == 1 && !info.insuranceUrl"
            class="btn"
            @click.stop="showVisitGuide(info, info.product.guideText, info.visitUrl)"
          >
            回访
          </button>
          <button v-if="info.role == 1 || info.role == 3" class="btn" @click.stop="showSurrenderGuide(info.product.guideText)">退保</button>
          <button class="btn" @click.stop="showClaimGuide(info.product.guideText)">理赔指引</button>
          <button class="btn" v-if="info.insuranceUrl" @click.stop="toUrl(info.insuranceUrl)">电子保单</button>
        </template>
        <!-- 已退保 -->
        <template v-if="info.newStatus == 4">
          <!-- 空 -->
        </template>
        <!-- 已失效  -->
        <template v-if="info.newStatus == 5">
          <button class="btn" @click="deleteOrder(info.id)">删除</button>
        </template>
        <!-- 已关闭  -->
        <template v-if="info.newStatus == 6">
          <button class="btn" @click="deleteOrder(info.id)">删除</button>
        </template>
      </div>
    </div>

    <!-- 缴费计划 -->
    <van-popup closeable v-model="renewPopup" title="缴费计划" round position="bottom" class="renew-popup">
      <div class="popup-title">缴费计划</div>
      <div v-if="info.renewTotal" class="renew-total">
        <p>已缴{{ info.renewTotal.paidPeriodNo }}期，共缴费{{ info.renewTotal.paidPremium }}元</p>
        <p>共{{ info.renewPeriodList.length }}期，还剩{{ info.renewTotal.unpaidPeriodNo }}期未交缴，未缴金额{{ info.renewTotal.unpaidPremium }}元</p>
      </div>
      <table class="renew-table">
        <tr
          v-for="r in info.renewPeriodList"
          :key="r.periodNo"
          :class="info.periodNo == r.periodNo ? 'cur' : info.periodNo > r.periodNo ? 'paid' : ''"
        >
          <td class="periodNo">第{{ r.periodNo }}期</td>
          <td class="payTime">
            <p>应缴日期：{{ getDate(r.shouldPayTime) }}</p>
            <p v-if="info.periodNo >= r.periodNo">实缴日期：{{ getDate(r.payTime) }}</p>
          </td>
          <td class="payMoney">
            <p>
              ¥<span class="number">{{ r.shouldPayMoney }}</span>
            </p>

            <p v-if="info.periodNo >= r.periodNo">{{ r.payStatusName }}</p>
          </td>
        </tr>
      </table>
    </van-popup>

    <van-popup closeable v-model="claimGuideVisible" title="理赔指引" round position="bottom" class="guide-popup">
      <div class="popup-title">理赔指引</div>
      <p class="title" v-if="claimGuide.firstStep">1.报案</p>
      <p class="desc" v-if="claimGuide.firstStep" v-html="claimGuide.firstStep"></p>
      <p class="title" v-if="claimGuide.secondStep">2.准备材料</p>
      <p class="desc" v-if="claimGuide.secondStep" v-html="claimGuide.secondStep"></p>
      <p class="title" v-if="claimGuide.thirdStep">3.保险公司核准</p>
      <p class="desc" v-if="claimGuide.thirdStep" v-html="claimGuide.thirdStep"></p>
      <p class="title" v-if="claimGuide.fourthStep">4.出具结果</p>
      <p class="desc" v-if="claimGuide.fourthStep" v-html="claimGuide.fourthStep"></p>
    </van-popup>
    <van-popup closeable v-model="visitGuideVisible" title="回访指引" round position="bottom" class="guide-popup">
      <div class="popup-title">回访指引</div>
      <div class="wrapper" v-for="(item, index) in visitGuide" :key="index">
        <p class="title" v-if="item.title">{{ item.title }}</p>
        <p class="desc" v-if="item.desc">{{ item.desc }}</p>
      </div>
    </van-popup>
    <van-popup closeable v-model="surrenderGuideVisible" title="退保指引" round position="bottom" class="guide-popup">
      <div class="popup-title">退保指引</div>
      <div class="wrapper" v-for="(item, index) in surrenderGuide" :key="index">
        <p class="title" v-if="item.title">{{ item.title }}</p>
        <p class="desc" v-if="item.desc">{{ item.desc }}</p>
      </div>
    </van-popup>
    <van-popup closeable v-model="preservationGuideVisible" title="保全指引" round position="bottom" class="guide-popup">
      <div class="popup-title">保全指引</div>
      <div class="wrapper" v-for="(item, index) in preservationGuide" :key="index">
        <p class="title" v-if="item.title">{{ item.title }}</p>
        <p class="desc" v-if="item.desc">{{ item.desc }}</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getOrderDetail, deleteOrder, getOrderRenewalList } from "../../api/api"
import { getSession } from "../../util/util"
import Explain from "../../components/Explain.vue"
import loadCustomerService from "@/mixins/loadCustomerService"
export default {
  components: {
    Explain,
  },
  mixins: [loadCustomerService],
  data() {
    return {
      id: "",
      info: {
        name: "",
        applicant: {},
        surrender: {},
        insured: [],
        beneficiary: [],
        manual: {
          status: "",
        },
        product: {},
      },
      showMore: false,
      moreBtnOptions: [{ text: "变更信息" }, { text: "回访" }],
      claimGuideVisible: false,
      claimGuide: {
        firstStep: "",
        secondStep: "",
        thirdStep: "",
        fourthStep: "",
      },
      visitGuideVisible: false,
      visitGuide: [],
      preservationGuideVisible: false,
      preservationGuide: [],
      surrenderGuideVisible: false,
      surrenderGuide: [],
      showBottomBar: true,
      // 续期
      renewPopup: false,
      statusOpetion: {
        1: "待支付",
        2: "已支付",
        3: "保障中",
        4: "已退保",
        5: "已失效",
        6: "已关闭",
      },
      renewalList: [],
      csInfo: [],
    }
  },
  created() {
    const { id } = this.$route.query
    this.id = id
    if (!id) {
      this.$toast("订单不存在！")
      return
    }
    this.getOrderDetail(id)
  },
  mounted() {
    // this.$nextTick(() => {})
  },
  watch: {},
  methods: {
    async deleteOrder(orderId) {
      this.$dialog
        .confirm({
          message: "请确认是否删除？",
          confirmButtonColor: "#fe8a3d",
          confirmButtonText: "删除",
          cancelButtonText: "取消",
        })
        .then(async () => {
          await deleteOrder({ orderId })
          this.$toast("删除成功")
          this.$router.go(-1)
        })
    },
    async getOrderDetail(orderId) {
      const toast = this.$toast.loading({
        overlay: true,
        forbidClick: true,
        message: "请稍后...",
        duration: 0,
      })
      const res = await getOrderDetail({ orderId })
      toast.clear()
      this.info = res.data

      const { isSupportRenewal, isSupportReNew } = this.info

      // 长险是否支持续期
      if (isSupportReNew) {
        const _total = {
          paidPremium: 0, //已缴保费
          paidPeriodNo: 0, //已缴期数
          unpaidPeriodNo: 0, //未缴期数
          unpaidPremium: 0, //未缴保费
        }

        this.info.renewPeriodList.forEach((element) => {
          // 找出当期数据
          if (this.info.periodNo == element.periodNo) {
            this.info.curPeriod = element
          }

          // 统计总保费、缴费记录
          if (element.payStatus == 2) {
            // 已支付
            _total.paidPremium += Number(element.payMoney)
            _total.paidPeriodNo++
          } else {
            // 未支付
            _total.unpaidPremium += Number(element.shouldPayMoney)
            _total.unpaidPeriodNo++
          }
          this.info.renewTotal = _total
        })
      }

      // 短险是否支持续保
      if (isSupportRenewal) {
        getOrderRenewalList({
          orderId: this.id,
        }).then((res) => {
          if (res.status == 0) {
            this.renewalList = res.data.list
          } else {
            this.$toast(res.msg)
          }
          console.log(res)
        })
      }

      this.$nextTick(() => {
        if (this.$refs.btnRight.children.length == 0) {
          this.showBottomBar = false
        }
      })
    },

    handleCsInfo(info) {
      const userInfo = getSession("policyToolsInfo") || {}
      const { id, name, mobile } = userInfo
      const { source } = this.$route.query

      let adminUrl = process.env.VUE_APP_ADMIN
      let sourceMap = {
        "/orderList": "保单大师-我的订单-订单详情",
        "/muwOrderList": "保单大师-我的人核订单-订单详情",
        "/claim": "保单大师-理赔管理-订单详情",
      }

      this.csInfo = [
        {
          key: "uid",
          label: "用户id",
          value: id,
        },
        {
          key: "uid",
          label: "用户名称",
          value: name,
        },
        {
          key: "mobile",
          label: "用户电话",
          value: mobile,
        },
        {
          key: "referringPage",
          label: "来源",
          value: sourceMap[source],
        },
        {
          key: "productName",
          label: "商品名称",
          value: info.productName,
        },
        {
          key: "orderSn",
          label: "订单号",
          value: info.orderSn,
          href: `${adminUrl}/order/detail/${this.id}`,
        },
        {
          key: "insuranceSn",
          label: "保单号",
          value: info.insuranceSn ? info.insuranceSn : "未知",
        },
        {
          key: "applicantInsured",
          label: "投/被保人",
          value: `${info.applicant.name}${"/" + info.insured[0].name}`,
        },
        {
          key: "premium",
          label: "保费",
          value: info.premium,
        },
        {
          key: "status",
          label: "订单状态",
          value: info.statusName,
        },
        {
          key: "visitStatus",
          label: "回访状态",
          value: info.visitStatus || "未知",
        },
        {
          key: "visitType",
          label: "回访类型",
          value: info.visitType || "未知",
        },
      ]
      this.showChat()
    },
    serviceDialog(info, type) {
      let message = ""
      switch (type) {
        case "claim":
          message = "您好，暂不支持在线理赔，请联系客服处理。"
          break
        case "visit":
          message = "您好，暂不支持在线回访，请联系客服处理。 "
          break
        case "preservation":
          message = "您好，暂不支持在线变更信息，请联系客服处理。"
          break
        case "surrender":
          message = "您好，暂不支持在线退保，请联系客服处理。 "
          break
        case "url":
          message = "您好，该链接请咨询客服~ "
          break
      }
      this.$dialog
        .confirm({
          message,
          confirmButtonColor: "#fe8a3d",
          confirmButtonText: "立即咨询",
          cancelButtonText: "关闭",
        })
        .then(() => {
          try {
            this.handleCsInfo(info)
          } catch (error) {}
        })
        .catch(() => {
          // on cancel
        })
    },
    toUrl(url) {
      if (url) {
        window.location.href = url
      } else {
        this.serviceDialog(this.info, "url")
      }
    },
    showVisitGuide(data, visitUrl) {
      this.showMore = false
      if (visitUrl) {
        window.location.href = visitUrl
      } else {
        if (data.visit.length > 0 && data.visit[0].title == "" && data.visit[0].desc == "") {
          this.serviceDialog(this.info, "visit")
          return
        }
        this.visitGuide = data.visit
        this.visitGuideVisible = true
      }
    },
    showClaimGuide(data) {
      if (!data.claim.firstStep) {
        this.serviceDialog(this.info, "claim")
        return
      }
      this.claimGuide = data.claim
      this.claimGuideVisible = true
    },
    showSurrenderGuide(data) {
      if (this.info.surrenderUrl) {
        window.location.href = this.info.surrenderUrl
      } else {
        if (data.surrender.length > 0 && data.surrender[0].title == "" && data.surrender[0].desc == "") {
          this.serviceDialog(this.info, "surrender")
          return
        }
        this.surrenderGuide = data.surrender
        this.surrenderGuideVisible = true
      }
    },
    showPreservationGuide(data) {
      this.showMore = false

      if (data.preservation.length > 0 && data.preservation[0].title == "" && data.preservation[0].desc == "") {
        this.serviceDialog(this.info, "preservation")
        return
      }
      this.preservationGuide = data.preservation
      this.preservationGuideVisible = true
    },

    // 获取日期
    getDate(dateTime) {
      return dateTime.split(" ")[0]
    },
  },
}
</script>
<style lang="less" scoped>
.order-detail-container {
  // height: 100vh;
  position: relative;
  padding-bottom: 154px;
  background-color: #fbfbfb;
  .service-side {
    position: fixed;
    bottom: 10%;
    right: -15px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    z-index: 99;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .base-info-wrapper {
    // min-height: 680px;
    .bg {
      width: 750px;
      padding-top: 50px;
      background: url("../../assets/image/order-detail-banner.png") no-repeat;
      background-size: 750px 400px;
      position: relative;
    }
    // .produ
    .title {
      width: 550px;
      padding-left: 48px;
      font-size: 42px;
      font-weight: 600;
      color: #ffffff;
      line-height: 59px;
    }
    .base-info {
      position: relative;
      margin-top: 46px;
      margin-left: 24px;
      width: 702px;
      padding: 24px;
      font-size: 26px;
      background-color: #fff;
      box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      .seal {
        position: absolute;
        top: 143px;
        right: 24px;
        width: 138px;
        height: 138px;
      }
      .top-wrapper {
        padding-bottom: 10px;
        // border-bottom: 1px dashed #e0e0e0;
        p {
          line-height: 37px;
          font-size: 30px;
          display: flex;
          &:nth-child(n + 2) {
            margin-top: 18px;
          }
          .label {
            flex: 0 0 4.5em;
            color: #777777;
          }
          .value {
            flex: 1;
            margin-left: 30px;
            color: #333333;
          }
        }
      }
      .bottom-wrapper {
        h4 {
          height: 45px;
          margin-top: 24px;
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          line-height: 45px;
        }
        .content {
          width: 654px;
          margin-top: 16px;
          padding: 0;
          // background-color: #fafafa;
          border-radius: 20px;
          box-shadow: none;
        }
      }
    }
  }

  .order-status {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 104px;
    padding: 0 16px;
    height: 44px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    line-height: 44px;
    border-radius: 0px 20px 0px 16px;
    &.guarantee {
      background: linear-gradient(171deg, #a2d5ff 0%, #5aa6ff 100%);
    }
    &.surrender {
      background-color: #98999a;
    }
    &.orange {
      background-color: #fe8a3d;
    }
  }

  .insured-section {
    .insured-item {
      &:nth-child(2) {
        margin-top: 40px;
      }

      .insured-no {
        height: 45px;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        line-height: 45px;
      }
      .insured-content {
        width: 654px;
        min-height: 390px;
        margin-top: 16px;
        padding: 24px;
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }

  .beneficiary-section {
    .common-content {
      .ben-order {
        &:nth-child(n + 2) {
          margin-top: 24px;
        }
        &:nth-child(n + 3) {
          margin-top: 40px;
        }
        .ben-no {
          height: 33px;
          font-size: 24px;
          color: #777777;
          line-height: 33px;
        }
        .ben-content {
          width: 654px;
          padding: 24px;
          margin-top: 16px;
          background-color: #fafafa;
          border-radius: 20px;
          .ben-item {
            &:nth-child(n + 2) {
              padding-top: 24px;
              border-top: 1px solid #e3e3e3;
            }
            padding-bottom: 24px;
          }
        }
      }
    }
  }
  .bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 114px;
    padding: 0 24px;
    background-color: #fff;
    z-index: 99;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08);
  }
  .btn-group {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .service {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.guarantee {
        padding-right: 16px;

        // &::after {
        //   content: '';
        //   position: absolute;
        //   top: 0;
        //   right: -16px;
        //   width: 1px;
        //   height: 60px;
        //   border: 1px solid #e0e0e0;
        // }
      }
      span {
        color: #777777;
        font-size: 20px;
      }
      img {
        width: 31px;
        height: 30px;
      }
    }
    .van-popover__wrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    .more {
      color: #777777;
      height: 37px;
      font-size: 26px;
      color: #777777;
      // line-height: 37px;
      margin-left: 16px;
    }
    .right {
      margin-left: auto;
      display: flex;
    }
    .btn {
      // width: 100%;
      // flex: 1;
      min-width: 168px;
      padding: 0 24px;
      height: 68px;
      margin-left: 16px;
      border-radius: 34px;
      border: 1px solid #cecfd0;
      text-align: center;
      line-height: 68px;
      font-size: 26px;
      color: #333333;
      background-color: #fff;
    }
    .orange-btn {
      border-color: #fe8a3d;
      color: #fe8a3d;
    }
  }
  .common-content {
    width: 702px;
    padding: 24px;
    background-color: #fff;
    margin-top: 16px;
    box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    position: relative;

    .product-name {
      font-weight: 500px;
      font-size: 36px;
    }

    p {
      display: flex;
      font-size: 30px;
      line-height: 42px;
      &:nth-child(n + 2) {
        margin-top: 18px;
      }
      .label {
        flex: 0 0 4.5em;
        color: #777777;
      }
      .value {
        flex: 1;
        margin-left: 30px;
        color: #333333;
      }
    }
  }
  .section {
    width: 702px;
    margin: 40px auto 0;

    .title {
      display: inline-block;
      position: relative;
      font-size: 36px;
      font-weight: 600;
      color: #333333;
      line-height: 45px;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 128px;
        height: 16px;
        background: linear-gradient(90deg, rgba(255, 234, 209, 0.34) 0%, #fed1a4 100%);
      }

      p {
        position: relative;
        z-index: 1;
      }
    }
  }
}

.renew-popup {
  font-size: 24px;
  padding: 20px;

  .renew-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;

    tr {
      border-top: 1px dashed #eee;
      &:first-child {
        border-top: none;
      }
      &.cur {
        color: #f00;
      }

      &.paid {
        color: #ccc;
      }
    }

    td {
      padding: 12px 0;
      line-height: 1.5;

      &.periodNo {
        font-size: 30px;
        padding-left: 20px;
      }

      &.payTime {
        text-align: center;
        padding: 0 10px;
      }

      &.payMoney {
        .number {
          font-size: 30px;
        }
      }
    }
  }
}

.renew-total {
  font-size: 28px;
  background-color: #f7f7f7;
  padding: 15px 20px;
  color: #fe8a3d;
  line-height: 1.5;
  margin-top: 15px;
}
</style>
