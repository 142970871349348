export default {
  data() {
    return {
      customerPageDom: null,
      customerIframe: null,
    }
  },
  mounted() {
    this.customerPageDom = document.getElementById("lc-web-chat-page")
    this.customerIframe = document.getElementById("lc-web-chat-iframe")
    this.closeBtn = document.querySelector("#lc-web-chat-page .close-btn")
    this.closeBtn.addEventListener("click", this.closeChat)
  },
  methods: {
    hideChat() {
      this.customerPageDom.style.display = "none"
    },
    closeChat() {
      this.customerPageDom.style.display = "none"
      this.customerIframe.setAttribute("src", "")
    },
    showChat() {
      this.customerPageDom.style.display = "block"
      console.log("this.csInfo: ", this.csInfo)
      // businessParam示例 a:1,b:2,c:3 csInfo是一个对象数组
      // 取出csInfo中的label和value label是a b是value 第一个不要逗号
      let businessParam = ""
      if (this.csInfo && this.csInfo.length > 0) {
        for (let i = 0; i < this.csInfo.length; i++) {
          if (i == 0) {
            businessParam += `${this.csInfo[i].label}:${this.csInfo[i].value}`
          } else {
            businessParam += `,${this.csInfo[i].label}:${this.csInfo[i].value}`
          }
        }
      }
      console.log("businessParam: ", businessParam)
      if (!this.customerIframe.getAttribute("src")) {
        businessParam = `&businessParam=${businessParam}`
        this.customerIframe.setAttribute(
          "src",
          `https://im4d0f274.7x24cc.com/phone_webChat.html?accountId=N000000038489&chatId=71d53bfa-bf85-4860-80ab-b618c28f84ed${businessParam}`
        )
      }
    },
  },
}
