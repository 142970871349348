<template>
  <div class="explain-block">
    <van-icon class="icon" name="question-o" @click.stop="popupShow = true" />

    <van-popup round v-model="popupShow" class="explain-pop">
      <div class="custom-popup-content">
        <div v-if="title" class="custom-popup-header">
          {{ title }}
        </div>
        <div class="description-content">
            <div v-html="value"></div>
            <slot></slot>
        </div>
        <div class="custom-popup-footer-btn" @click="popupShow = false">
          <span>我知道了</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "Explain",
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      popupShow: false
    };
  }
};
</script>

<style lang="less" scoped>
.explain-block {
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  line-height: 1;

  .icon {
    color: #047afe;
    position: relative;
    z-index: 2;
  }
}

.explain-pop {
  width: 80%;
  max-height: 90%;
  text-align: center;

  .description-content {
    line-height: 1.6;
    font-size: 26px;
    max-height: 700px;
    overflow-y: auto;
  }

  .title {
    text-align: center;
  }
}

// 自定义弹窗内容区域
.custom-popup-content {
  height: 100%;
  position: relative;
  box-sizing: border-box;

  .custom-popup-header {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
    background-color: #fff;
    padding: 20px;

    h3 {
      font-size: 32px;
      font-weight: 500;

      .icon {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin-top: -3px;
      }
    }

    .des {
      font-size: 24px;
      color: #777777;
    }
  }
}

.description-content {
 padding: 30px;
}

.custom-popup-footer-btn {
  text-align: center;
  height: 90px;
  line-height: 90px;
  font-size: 26px;
  border-top: 1px solid #ebedf0;
  color: #ff7300;
}
</style>
