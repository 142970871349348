import { render, staticRenderFns } from "./OrderDetail.vue?vue&type=template&id=691c73fb&scoped=true&"
import script from "./OrderDetail.vue?vue&type=script&lang=js&"
export * from "./OrderDetail.vue?vue&type=script&lang=js&"
import style0 from "./OrderDetail.vue?vue&type=style&index=0&id=691c73fb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691c73fb",
  null
  
)

export default component.exports